<div class="mt-3">
  <div class="flex items-center" mat-dialog-title>
    <h2 class="headline m-0 flex-auto">Přiřazení telefonisty</h2>
    <button class="text-secondary" mat-dialog-close mat-icon-button type="button">
      <mat-icon svgIcon="mat:close"></mat-icon>
    </button>
  </div>
</div>
<mat-dialog-content class="m-0">
  <mat-divider class="text-border"></mat-divider>

  @if(!loading) {
    <mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
      <mat-label>Telefonista:</mat-label>
      <mat-select (valueChange)="onTelephonistSelect($event)" [(ngModel)]="telephonistId" [placeholder]="'Vyberte telefonistu'">
        <mat-option>Žádný telefonista</mat-option>
        @for(tel of telephonists; track tel) {
          <mat-option [value]="tel.id" class="text-xs">
            <div class="flex flex-row items-center gap-2">
              @if(tel.photo) {
                <img [src]="tel.photo | photo:'/rs:fill:100:100:1/g:no/' | async" class="avatar h-8 w-8 align-middle">
              }
              <span>{{tel?.firstname}} {{tel?.lastname}}</span>
            </div>
          </mat-option>
        }

      </mat-select>
    </mat-form-field>
  }

  <mat-divider class="text-border"></mat-divider>
  <ng-container *ngIf="telephonistWithLimits">
    <canvas-limitation-telephonist-card [telephonist]="telephonistWithLimits" [highlightLastRow]="true"></canvas-limitation-telephonist-card>
  </ng-container>
</mat-dialog-content>

<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close type="button">Zavřít</button>
  <button color="primary" mat-flat-button (click)="save()">Uložit</button>
</mat-dialog-actions>
