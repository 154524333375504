import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {
  ModxClient,
  UserManagementService, UserWithRelations
} from '../../../../@backend';

import {CommonModule} from "@angular/common";
import {MatSelectModule} from "@angular/material/select";
import {FormControl, FormsModule, ReactiveFormsModule} from "@angular/forms";
import {PhotoPipe} from "../../pipes/photo.pipe";
import {UserLocalService} from "../../services/userLocal.service";
import {
  MatAutocomplete,
  MatAutocompleteModule,
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger
} from "@angular/material/autocomplete";
import {MatInputModule} from "@angular/material/input";
import {MatButtonModule} from "@angular/material/button";
import {Observable} from "rxjs";
import {map, startWith} from "rxjs/operators";

@Component({
  standalone: true,
  imports: [CommonModule, MatSelectModule, FormsModule, ReactiveFormsModule, PhotoPipe, MatInputModule, MatButtonModule, MatAutocompleteModule],
  selector: 'canvas-user-select-autocomplete',
  templateUrl: './user-select-autocomplete.component.html',
})
export class UserSelectAutocompleteComponent implements OnInit {

  @Output() user = new EventEmitter<UserWithRelations>();
  @Input() selectUserId?: string | number = null;
  @Input() useModxId?: boolean = true;
  @Input() displayAll?: boolean = true;
  @Input() returnModxId?: boolean = true;
  @Input() label?: string = 'Klienti uživatele:';
  @Input() showRoles?: boolean = false;
  @Input() defaultOption?: string = 'Všichni uživatelé';
  @Input() defaultHint?: string | undefined = undefined;


  loading = true;
  users: Array<UserWithRelations>;
  filteredUsers: Observable<UserWithRelations[]>;
  selectedUser: UserWithRelations;


  searchControl = new FormControl<UserWithRelations | string>('');

  constructor(private userService: UserManagementService, private userLocalService: UserLocalService,) {


  }

  async ngOnInit(): Promise<void> {
    this.loading = true;
    // this.users = await firstValueFrom(this.userService.userManagementControllerGetUsersForSelect(this.showRoles));
    this.users = await this.userLocalService.getUsers();
    if(this.selectUserId) {
      if(this.useModxId) {
        this.selectedUser = this.users.find(i => i.modxUserId === this.selectUserId);
      }else {
        this.selectedUser = this.users.find(i => i.id === this.selectUserId);
      }
      this.searchControl.setValue(this.selectedUser);
    }else if(this.selectUserId === 0){
      this.selectedUser = null;
      this.selectUserId = null;
    }

    this.filteredUsers = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value || '')),
    );

    this.loading = false;
  }


  private _filter(value: string | UserWithRelations): UserWithRelations[] {
    if(typeof value !== 'string' || value === '') {
      return this.users;
    }
    return this.users.filter(user => {
      if(user?.firstname && user?.lastname) {
        return `${user.firstname} ${user.lastname}`.
          toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "")
          .includes(value.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, ""));
      } else {
        return false;
      }
    });
  }


  onUserSelect(option: MatAutocompleteSelectedEvent) {
    const user = option.option.value as UserWithRelations;
    console.log('onUserSelect', user);
    if(user) {
      this.selectedUser = user;
      this.user.emit(user);
    }else {
      this.selectedUser = null;
      this.user.emit(null);
    }
  }


  displayFn(user: UserWithRelations): string {
    return user ? user.firstname + ' ' + user.lastname : '';
  }


}
