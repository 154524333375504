@if(!loading){
<mat-form-field class="w-full" [subscriptSizing]="'dynamic'">
  <mat-label>{{label}}</mat-label>
  @if(defaultHint) {
    <mat-hint>{{defaultHint}}</mat-hint>
  }
  @if(selectedUser) {
    <div matIconPrefix class="px-2">
      <img [src]="selectedUser.photo | photo:'/rs:fill:100:100:1/g:no/' | async" class="avatar h-8 w-8 align-middle" alt="" />
    </div>
  }
  <input matInput type="text" [placeholder]="'Vyberte uživatele'" aria-label="Klient" [formControl]="searchControl" [matAutocomplete]="auto">
  <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onUserSelect($event)">
    @if(displayAll){
      <mat-option>{{defaultOption}}</mat-option>
    }
    @for (u of filteredUsers | async; track u.id) {
      <mat-option [value]="u" class="text-xs">
        <div class="flex flex-row items-center gap-2">
          @if(u.photo) {
            <img [src]="u.photo | photo:'/rs:fill:100:100:1/g:no/' | async" class="avatar h-8 w-8 align-middle" alt="" />
          }@else {
            <div class="relative inline-flex items-center justify-center h-8 w-8 overflow-hidden bg-gray-300 rounded-full dark:bg-gray-300">
              <span class="font-medium text-gray-600 dark:text-gray-500">{{u.firstname?.charAt(0)}}{{u.lastname?.charAt(0)}}</span>
            </div>
          }
          <div class="flex flex-col">
            <div>{{u?.firstname}} {{u?.lastname}}</div>
            @if(showRoles) {
              <span class="flex flex-row gap-1 text-xs text-gray-500 dark:text-gray-400">
                @for(role of u.roles; track role) {
                  <span>{{role.name}}</span>
                }
              </span>
            }
          </div>

        </div>
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
}
