import {Component, Input, OnInit} from '@angular/core';
import {AuthorWithRelations} from '../../../../@backend';
import {CommonModule} from '@angular/common';
import {RouterLink} from "@angular/router";

@Component({
  standalone: true,
  selector: 'kodl-author',
  imports: [CommonModule, RouterLink],
  template: `
    @for(author of authors; track author.id; let isLast = $last) {
    <a [routerLink]="'/autori/detail/' + author.id" class="hover:text-blue-500" [class.disabled]="!allowLink" >
      @if(author.nickname) {
        <span>
         {{author.nickname}}
          @if(showDating) {
            @if(author.birthday && author.death) {
              <span>
               ({{author.birthday}} - {{author.death}})
              </span>
            } @else if (author.birthday && (author.death == '' || author.death === null)) {
              <span>
               (*{{author.birthday}})
              </span>
            } @else if (author.death && (author.birthday == '' || author.birthday === null)) {
              <span>
               (†{{author.death}})
              </span>
            }
         }
        </span>
      }@else {
        <span>
          {{author.firstname}} {{author.lastname}}
          @if(showDating) {
            @if(author.birthday && author.death) {
              <span>
               ({{author.birthday}} - {{author.death}})
              </span>
            } @else if (author.birthday && (author.death == '' || author.death === null)) {
              <span>
               (*{{author.birthday}})
              </span>
            } @else if (author.death && (author.birthday == '' || author.birthday === null)) {
              <span>
               (†{{author.death}})
              </span>
            }
          }
        </span>
      }
        {{isLast ? '' : ', '}}
    </a>
    }
  `
})
export class AuthorComponent implements OnInit {

  @Input() authors: AuthorWithRelations[];
  @Input() showDating: boolean = false;
  @Input() allowLink: boolean = true;

  constructor() { }

  ngOnInit(): void {
  }

}
