<div class="flex flex-row w-full">

<div class="p-4 md:w-1/4 lg:w-1/4 items-center place-content-center">
  <a class="relative max-h-48 block overflow-hidden rounded" [routerLink]="'/dila/detail/' + limitSummary.item.id" routerLinkActive="active">
    <img [alt]="limitSummary.item.name" loading="lazy" class="block h-full w-full object-cover object-center cursor-pointer"
         [src]='limitSummary.item.photo|photoSecured:"1":"/plain/rs:fill:250:250:1/g:no/":limitSummary.item.shared | async' width="250" height="250" />
  </a>
</div>

<div class="p-4 md:w-1/4 lg:w-1/4">
  <div class="m-4">
    <div>
      <canvas-item-labels [itemId]="limitSummary.item.id" [prefixNumber]="limitSummary.item?.contract?.prefixNumber" [catalogNumber]="limitSummary.item.catalogNumber" [auctionNumber]="limitSummary.item?.auctionterms" [shared]="limitSummary.item.shared"></canvas-item-labels>
    </div>
    <a [routerLink]="'/dila/detail/' + limitSummary.item.id" routerLinkActive="active">
      <h2  class="title-font text-lg font-medium text-gray-900 inline" [innerHTML]="limitSummary.item.name"></h2>
    </a>
    <span *ngIf="limitSummary.item.datace"> ({{limitSummary.item.datace}})</span>
    <h3 class="title-font font-medium text-gray-900">
      <kodl-author [authors]="limitSummary.item.authors" [showDating]="true"></kodl-author>
    </h3>
    <div class="mt-1">{{limitSummary.item.technique}}</div>
    <div class="">
      {{limitSummary.item.height ? limitSummary.item.height : ''}}{{limitSummary.item.width  ? ' x ' + limitSummary.item.width : ''}}{{limitSummary.item.depth ? ' x ' + limitSummary.item.depth : ''}} cm
    </div>
    <div class="flex flex-col justify-items-end mt-2">
<!--      <button disabled mat-flat-button class="mat-button-small text-xs w-12 h-8"><mat-icon svgIcon="mat:edit"></mat-icon> Upravit</button>-->
    </div>
  </div>
</div>

<div class="md:w-1/4 lg:w-1/4 flex flex-col items-center justify-center bg-slate-100 text-center gap-2">

    <div class="flex flex-col">
        <div class="">Vyvolávací cena:</div>
        <div class="font-medium text-gray-900">{{limitSummary.item.auctionStartingPrice | currency:'CZK':'symbol':'0.0-0':'cs'}}</div>
    </div>

    <div class="flex flex-col">
        <div class="">Odhadovaná cena:</div>
        <div class="font-medium text-gray-900">{{limitSummary.item.auctionEstimatedPriceFrom | currency:'CZK':'symbol':'0.0-0':'cs'}} - {{limitSummary.item.auctionEstimatedPriceTo | currency:'CZK':'symbol':'0.0-0':'cs'}}</div>
    </div>

    <div class="flex flex-row gap-2">
        <div class="">Počet tel. limitů:</div>
        <div class="font-medium text-gray-900">{{limitSummary.telLimitCount}}</div>
    </div>

    <div class="flex flex-row gap-2">
        <div class="">Počet pevných limitů:</div>
        <div class="font-medium text-gray-900">{{limitSummary.fixedLimitCount}}</div>
    </div>

  @if (limitSummary.item.iapLimited) {
    <div class="flex flex-row gap-2">
      <div class="font-medium text-yellow-700">Limitováno v Artslimitu</div>
    </div>
  }


  <div class="flex flex-row gap-2">
        <div class="">Celkem limitů:</div>
        <div class="font-medium text-gray-900">{{limitSummary.totalLimitCount}}</div>
    </div>

</div>

<div class="md:w-3/5 lg:w-3/5 flex flex-row p-3">

            <div class="flex flex-col items-center w-1/2">
                <div class="font-bold text-center">Telefonní limit</div>

              @for(tel of limitSummary.telLimits; track tel) {
                <div class="m-2">
                  <a routerLink="/limitace/detail/{{tel.idLimitBase}}">
                    <div class="bg-green-700 text-xs font-medium inline-flex items-center p-2 rounded dark:bg-green-700 dark:text-blue-50 border border-green-500">
                      <mat-icon svgIcon="mat:phone"></mat-icon>
                      <div class="flex flex-col">
                        <div class="text-xs"><canvas-client-basic [client]="tel.client"></canvas-client-basic></div>
                        @if(tel.value) {
                          <div class="font-bold">{{tel.value | currency : 'CZK' : 'symbol' : '1.0-0'}}</div>
                        }
                      </div>
                    </div>
                  </a>
                </div>
              }

            </div>

            <div class="flex flex-col items-center w-1/2">
                <div class="font-bold text-center">Pevný limit</div>

                @for(fix of limitSummary.fixedLimits; track fix) {
                  <div class="m-2">
                    <a routerLink="/limitace/detail/{{fix.idLimitBase}}">
                      <div class="bg-blue-50 text-xs font-medium inline-flex items-center p-2 rounded dark:bg-blue-50 dark:text-blue-500 border border-blue-300">
                        <mat-icon svgIcon="mat:back_hand"></mat-icon>
                        <div class="flex flex-col">
                          <div class="text-xs"><canvas-client-basic [client]="fix.client"></canvas-client-basic></div>
                          <div class="font-bold">{{fix.value | currency : 'CZK' : 'symbol' : '1.0-0'}}</div>
                        </div>
                      </div>
                    </a>
                  </div>
                }
            </div>
    </div>

</div>
