import { Pipe, PipeTransform } from '@angular/core';
import {environment} from '../../../environments/environment';
import {ImgControllerService} from '../../../@backend';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {AuthenticationService} from '../services/authentication.service';
import {DomSanitizer, SafeHtml} from '@angular/platform-browser';
import {firstValueFrom} from "rxjs";

@Pipe({
  name: 'photoSingle'
})
export class PhotoSinglePipe implements PipeTransform {

  apiUrl: string = environment.baseApiUrl;
  defaultImg: string = 'https://canvas.galeriekodl.cz/assets/img/painting_default.jpg';

  constructor(private http: HttpClient, private authService: AuthenticationService, private sanitizer: DomSanitizer) {
  }

  /**
   *
   * @param value
   * @param type :1 = main image, :0 others
   * @param cdnUrl
   */
  async transform(filename: string, modifiers: string = "plain/rs:fill:500:500:1/g:no", shared = false, path: string = 'picture'): Promise<SafeHtml> {

    let imageSrc = '' as string;

    if(filename) {
      imageSrc = this.apiUrl + '/img/'+path+'/' + encodeURIComponent(modifiers) + "/" + filename + "?shared=" + shared;

      const token = this.authService.currentUser.token;
      const headers = new HttpHeaders({Authorization: `Bearer ${token}`});
      const imageBlob = await firstValueFrom(this.http.get(imageSrc, {headers, responseType: 'blob'}));

      if(imageBlob !== null) {
        const reader = new FileReader();
        return new Promise((resolve, reject) => {
          reader.onloadend = () => {
            if (typeof reader.result === "string") {
              let result = this.sanitizer.bypassSecurityTrustResourceUrl(reader.result);
              resolve(result);
            }
          }
          reader.readAsDataURL(imageBlob);
        });
      }else {
        return this.defaultImg;
      }

    }else{
      return this.defaultImg;
    }
  }
}
